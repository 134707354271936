<template>
	<div
		class="flex justify-around items-center text-[#808080] text-[10px] lg:text-[18px] h-[81px] px-5 lg:px-20 py-3"
	>
		<div>
			Mauri Computación
			<br />
			© Copyright {{ currentYear }}
		</div>
		<div class="flex items-center justify-between">
			<NuxtLink
				to="http://qr.afip.gob.ar/?qr=-Yc1tA0w2SlV_f1f-a2p0A,,"
				target="_F960AFIPInfo"
			>
				<img
					class="h-[25px] w-[18px] lg:h-[58px] lg:w-[42px] mx-3 lg:mx-5 pa-0"
					src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                    alt="imagen afip"
				/>
			</NuxtLink>

			<NuxtLink
				to="https://cace.org.ar/socios/directorio-de-socios/"
				target="_blank"
			>
				<img
					class="h-[25px] w-[46px] lg:h-[58px] lg:w-[106px] mx-3 lg:mx-5 pa-0"
					src="/images/cace.png"
                    alt="imagen cace"
				/>
			</NuxtLink>

			<NuxtLink
				to="http://www.ciiecca.org.ar/blog/el-banco-central-de-la-r-a-habilito-cobertura-para-pymes-importadoras-com-a-7874-bcra-exterior-y-cambios-1/post/bienvenidos-a-ciiecca-242"
				target="_blank"
                >
				<img
                    class="h-[25px] w-[36px] lg:h-[40px] lg:w-[84px] mx-4 lg:mx-5 pa-0"
                    src="/images/ciiecca.png"
                    alt="imagen ciieca"
				/>
			</NuxtLink>
		</div>
	</div>
</template>

<script lang="ts" setup>
const currentYear = new Date().getFullYear();
</script>

<template>
	<div class="relative w-full max-w-lg-[630px] hidden lg:flex justify-center">

		<div class="size-buscador flex items-center">
			<input
				v-model="buscador"
				class="size-buscador pe-10 ps-2"
				type="search"
				placeholder="¿Qué estas buscando?"
				@keyup.enter="onEnter"
				@input="onSearch($event.target)"
				@keydown.arrow-up.prevent="arrowUp"
				@keydown.arrow-down.prevent="arrowDown" 
			>
			<svg
				v-if="buscador==''"
				class="hidden lg:flex"
				style="margin-left:-3rem;"
				width="30px"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
				/>
			</svg>
			<svg
				v-if="buscador!=''"
				class="hidden lg:flex cursor-pointer"
				style="margin-left:-3rem;"
				width="31px"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
				@click="buscador='';showResultados=false"
			>
				<g
				id="SVGRepo_bgCarrier"
				stroke-width="0"/><g
				id="SVGRepo_tracerCarrier"
				stroke-linecap="round"
				stroke-linejoin="round"/><g
				id="SVGRepo_iconCarrier">
				<path
				d="M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z"
				fill="#000000"/>
				<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
				fill="#000000"/>
				</g>
			</svg>
		</div>

		<ul
			v-show="showResultados && buscador"
			id="result-list" 
			ref="resultList"
			class="size-buscador max-h-300px absolute top-10 z-10 bg-light-backgroundPage overflow-y-auto flex flex-col select-none"
		>
				<template 	
					v-for="(item,index) in items"
					:key="item.codigo"
				>
					<template v-if="!item.tipo">
						<NuxtLink
						  class="h-41px px-2 cursor-pointer capitalize text-15px flex items-center py-2 select-none"
						  :class="{ 'hover-result': selectedIndex == index }"
						  :to="item.nombre=='Categorias' ? 
							`/catalogo/categorias` 
							: `/catalogo`
							"
						>
							{{ item.nombre }}
						</NuxtLink>
						
					</template>
					<template v-if="item.tipo==0||item.tipo==1">
						<NuxtLink
							:id="`item_buscador-${index}`"
							:to="item.tipo==0 ? 
							`/catalogo/categoria/${item.codigo}-${convertToUrlFriendly(item.nombre)}` 
							: `/catalogo/subcategoria/${item.codigo}-${convertToUrlFriendly(item.nombre)}`
							"
							class="
							item
							h-40px
							py-10px
							px-2
							pointer
							capitalize
							transition
							duration-200
							text-15px
							text-565656
							flex 
							items-center"
							:class="{ 'hover-result': selectedIndex == index }"
						>
							{{ item.nombre }}
						</NuxtLink>
					</template>
					
					<NavbarItemBuscador
						v-if="item.tipo==2"
						:codigo="item.codigo"
						:selected-item="selectedIndex"
						:index="index"
						:nombre="item.nombre"
						:valor-buscador="buscador"
						:imagen="item.imagen"
						:envio-gratis="item.envioGratis"
						:precio="item?.precio"
						:tipo="item.tipo"
						@close-resultados="showResultados=false"
					/>					
				</template>
				<NuxtLink
					:id="`item_buscador-${items.length}`"
					class="item h-41px px-2 text-center py-3"
					:class="{ 'hover-result': selectedIndex == items.length }"
					:to="`/catalogo/buscador/${buscador}`"
				>
					Ver más resultados de <strong>"{{ buscador }}"</strong>
				</NuxtLink>
		</ul>
	</div>
</template>

<script lang="ts" setup>
import type { ItemBuscador } from '../types/products';

	import { useLogin } from '@/stores/states';
	import { useCarroCompras } from '@/stores/carrocompras';

	const router = useRouter()
	const config = useRuntimeConfig();
	const uselogin = useLogin();
	const usecarro = useCarroCompras();

	const showResultados = ref(true)
	const resultList = ref()
	const items = ref<ItemBuscador[]>([]);
	const selectedIndex = ref(-1);

	const loadingBuscador = ref(false)
	const buscador = ref('')
	const productos_en_carro = usecarro.carrito.map(e => ({
		codigo: e.codigo,
		cantidad: e.cantidad,
	}));
	const cancelRequest = ref(false)
	
	const buscadorProductos = inputSearch => {
		if (!inputSearch || inputSearch.length < 3) {
			items.value = [];
			return;
		}
		loadingBuscador.value = true;

		$fetch('/buscador-simple', {
			baseURL: config.public['apibase'],
			method: 'POST',
			headers: {
				Authorization: `Bearer ${uselogin.token}`,
			},
			body: {
                query: inputSearch,
				// productos: productos_en_carro,
                pagina: 1,

            },
			dedupe: 'cancel',
			onResponse: ({ response }) => {
                // console.log(response);
				if (response.status == 200) {
					items.value = [];
					// FEAT: la propiedad prependIcon se puede usar para el historial de busqueda
					// items.value = [
					// 	{
					// 	prependIcon: 'mdi-clock-outline',
					// 	title: 'recipe with chicken',
					// 	},
					// 	{
					// 	prependIcon: 'mdi-clock-outline',
					// 	title: 'best hiking trails near me',
					// 	},
					// ]

					// if (
					// 	response._data.categorias.length > 0 ||
					// 	response._data.subcategorias.length > 0
					// ) {
					// 	items.value.push({
					// 		codigo: 0,
					// 		title: 'Categorias',
					// 		imagen: null,
					// 		envioGratis: null,
					// 	});
					// }

					// if (response._data.categorias.length > 0) {
					// 	items.value = [
					// 		...items.value,
					// 		...response._data.categorias.map(e => ({
					// 			codigo: e.codigo,
					// 			title: e.nombre,
					// 			imagen: e?.imagen,
					// 			envioGratis: false,
					// 			tipo: 0,
					// 		})),
					// 	];
					// }
					// if (response._data.subcategorias.length > 0) {
					// 	items.value = [
					// 		...items.value,
					// 		...response._data.subcategorias.map(e => ({
					// 			codigo: e.codigo,
					// 			title: e.nombre,
					// 			imagen: e?.imagen,
					// 			envioGratis: false,
					// 			tipo: 1,
					// 		})),
					// 	];
					// }

					if (response._data.productos.length > 0) {
						items.value.push({
							codigo: 0,
							title: 'Productos',
							imagen: null,
							envioGratis: null,
						});
					}

					items.value = [
						...items.value,
						...response._data.productos.map(e => ({
							...e,
							tipo: 2,
						})),
					];
				} else {
					items.value = [];
				}
				loadingBuscador.value = false;
				if(buscador.value){
					showResultados.value = true
				}
			},
		})
	};
	const onSearch = debounce(val => {
		
		cancelRequest.value = false
		if (!val.value || val.value == '') {
			buscador.value = '';
			items.value = [];
			return;
		}
		//Se resetea indice de elemento seleccionado(funcionalidad de flechas)
		if( import.meta.client ) {
			const listado = document.getElementById('result-list')
			listado.scrollTop = 0
		}
		selectedIndex.value = -1
		buscador.value = val.value;
		buscadorProductos(val.value);
	}, 300);

	const onEnter = (e) => {
		// si hay un resultado seleccionado con las flechas se navegara
		//hacia ese producto
		// console.log({e})
		showResultados.value = false;
		cancelRequest.value = true;
		const itemSeleccionado = items.value[selectedIndex.value]

		if(selectedIndex.value > -1 && itemSeleccionado) {
			
			buscador.value = '';
			/**
			 * Se navega segun el tipo de elemento seleccionado
			 */
			switch (itemSeleccionado?.tipo) {
				case 0:
					router.push(`/catalogo/categoria/${itemSeleccionado.codigo}-${convertToUrlFriendly(itemSeleccionado.title)}`)
				break;
				case 1: 
					router.push(`/catalogo/subcategoria/${itemSeleccionado.codigo}-${convertToUrlFriendly(itemSeleccionado.title)}`)
				break;
				case 2: 
					router.push(`/catalogo/${itemSeleccionado.codigo}-${convertToUrlFriendly(itemSeleccionado.title)}`)
				break;
				default:
					itemSeleccionado?.title == 'Categorias' ? router.push(`/catalogo/categorias`) : router.push(`/catalogo`)
				break;
			}
			selectedIndex.value = -1
		}else{
			showResultados.value = false;
			cancelRequest.value = true; 
			router.push(`/catalogo/buscador/${e.target.value}`); 
		}
		buscador.value = ''
		
		showResultados.value = false
	}
	const resaltarResultado = n => {
		if (!buscador.value && n) {
			return n;
		}
		if(!n){
			return
		}
		
		const retorno = n.replace(
			buscador.value.toLowerCase(),
			`<strong>${buscador.value}</strong>`
		);
		return retorno;
	};
	const handleClickOutside = event => {

		if (!resultList.value.contains(event.target)) {
			showResultados.value = false;
		}
	}
	const arrowUp = () => {
		if(selectedIndex.value == -1) {
			selectedIndex.value = items.value.length 
			return
		}

		selectedIndex.value -= 1

		//Este codigo genera PROBLEMAS con el navbar al intentar scrollear hacia arriba
		if(import.meta.client) {
			const item = document.getElementById(`item_buscador-${selectedIndex.value}`)
			if(item) {
				item?.scrollIntoView({ behavior: 'smooth', block: 'end' })
			}
		}
	}
	const arrowDown = () => {
		selectedIndex.value < items.value.length ? selectedIndex.value+=1 : selectedIndex.value = 0
		if(selectedIndex.value == -1) {
			if( import.meta.client ) {
				const listado = document.getElementById('result-list')
				listado.scrollTop = 0
			}
		}
		if(import.meta.client) {
			const item = document.getElementById(`item_buscador-${selectedIndex.value}`)
			if(item) {
				item?.scrollIntoView({ behavior: 'smooth', block: 'end' })
			}
		}


	}
	onMounted(() => {
		document.body.addEventListener('click', handleClickOutside, { passive: true });
	})

	onBeforeUnmount(() => {
		document.body.removeEventListener('click', handleClickOutside);
	})
</script>

<style scoped>
strong {
	font-weight:700 !important;
}


.pe-10 {
	padding-right: 2.5rem;
}
.ps-2 {
	padding-left: 0.5rem;
}

.absolute {
	position: absolute;
}
.right-1 {
	right: 0.25rem;
}
.top-05 {
	top: 50%;
}
.-translate-y-05 {
	transform: translateY(-50%);
}
.max-h-300px {
	max-height: 300px;
}
.top-10 {
	top: 2.6rem;
}
.z-10 {
	z-index: 10;
}
.bg-light-backgroundPage {
	background-color: #F3F4F6; /* Reemplaza con el color correcto */
}
.overflow-y-auto {
	overflow-y: auto;
}
.flex {
	display: flex;
}
.flex-col {
	flex-direction: column;
}
.h-41px {
	height: 41px;
}
.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.text-15px {
	font-size: 15px;
}
.text-474747 {
	color: #474747;
}
.my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.h-40px {
	height: 40px;
}
.py-10px {
	padding-top: 10px;
	padding-bottom: 10px;
}
#result-list .item:hover {
	background-color: #E5E7EB;
}
.hover-result {
	background-color: #1c1d2049;
	cursor: pointer;
	border:1px solid red;
}
.transition {
	transition-property: all;
}
.duration-200 {
	transition-duration: 200ms;
}
.text-565656 {
	color: #565656;
}
#result-list {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
	overscroll-behavior: contain;
}
@media (min-width: 960px) {
	input {
		height: 42px;
		border: 0.6px solid #7a7a7a;
		border-radius: 8px;
	}
}
@media (min-width: 1024px) {
	#result-list .item {
		transition: background-color 0.3s;
	}
	#result-list .item:hover {
		background-color: #D9D9D9;
		cursor: pointer;
	}
	input::placeholder {
		font-weight: 300;
		font-size: 15px;
		line-height: normal;
	}

	input:focus {
		border: 0.6px solid #7a7a7a !important;
	}

	.size-buscador {
		font-family: Roboto !important;
		width:95%;
		margin-inline: 10px !important;
		max-width: 636px;
	}

}
</style>
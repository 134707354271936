import { ref } from 'vue'
import { useLogin } from '../stores/states'
import type { ResponseDatosNavbar } from '../types/responses'

export function useMenuData() {
  const menu = ref<ResponseDatosNavbar>([])
  const uselogin = useLogin()
  const config = useRuntimeConfig()

  const fetchMenuData = async () => {
    try {
      const response = await $fetch<ResponseDatosNavbar>('/datos_navbar', {
        method: 'GET',
        baseURL: config.public['apibase'],
        headers: {
          Authorization: `Bearer ${uselogin.token}`,
        },
      })
      menu.value = response
    } catch (error) {
      console.error('Error fetching menu data:', error)
    }
  }

  fetchMenuData()

  return { menu }
}
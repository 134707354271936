<script lang="ts" setup>
	
	import { useDataEmpresa } from '../stores/states';

	const config = useRuntimeConfig();
	const route = useRoute()
    const data = useDataEmpresa()

	
	useHead(() => ({
        link: [
            { rel: 'canonical', href: config.public['url'] + route.path },
		],
	}))
    
    
        
    const routes_cart = [
        '/carro-compras', 
        '/formas-pago',
        '/formas-envio',
        '/datos-cliente',
        '/resumen-compra',
        '/detalle-compra'
    ]
    
	const { data: dataEmpresa } = await useAsyncData<ResponseDatosEmpresa>(
		'info-empresa',
		()=> $fetch('/datos_empresa', {
				method: 'GET',
				baseURL: config.public['apibase'],
				getCachedData: (key) =>{
					const cachedData = nuxtApp.payload.data[key] ?? nuxtApp.static.data[key]
					
					if( !cachedData ) {
						return
					}
					//Si la data es mas antigua (2 horas), se hace un nuevo fetch
					const expirationDate = new Date(cachedData?.fetchedAt);
                    expirationDate.setTime(expirationDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours
                    return expirationDate.getTime() > Date.now() ? cachedData : undefined;
				},
			}), {
				transform: (data) => {return{...data, fetchedAt: new Date()}},
			}
			
		);

	if(dataEmpresa.value) {
		data.whatsappLink = dataEmpresa.value?.datos_empresa?.whatsapp_link
		data.whatsapp = dataEmpresa.value?.datos_empresa?.whatsapp
	}
</script>
<template>
	<div id="layout_main">
		<ComunesNavbar
		:data-empresa="dataEmpresa.datos_empresa"
		/>
		<main class="min-h-screen">
			<slot/>
		</main>
		<ComunesFooter :data-empresa="dataEmpresa.datos_empresa" />
		
		
		
		
        
        <template v-if="!routes_cart.includes($route.path)">
            <ClientOnly>
                <BtnScrollTop />
                <BtnWpp />
            </ClientOnly>
        </template>

	</div>
</template>
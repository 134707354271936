<script setup>
	const props = defineProps({
		codigo: { type: Number, required: true },
		nombre: { type: String, required: true },
		valorBuscador: { type: String, required: true },
		imagen: { type: String, required: false },
		precio: { required: false },
		resaltar: { type:Boolean, required:false },
		tipo: { required: true },
		envioGratis: { type: Boolean, required: false },
    selectedItem: { type: Number, required: false },
    index: { type: Number, required: false },
	})
  defineEmits(['closeResultados'])
  const resaltarResultado = n => {
		if(!n){
			return
		}

    const retorno = n.toLowerCase().replace(props.valorBuscador.toLowerCase(),`<b>${props.valorBuscador}</b>`);

    return retorno;

	};

</script>
<template>
  <div>
    <NuxtLink
      :id="`item_buscador-${index}`"
      class="p-container"
      :class="{ 'hover-result': (selectedItem) === index }"
      :to="`/catalogo/${codigo}-${convertToUrlFriendly(nombre)}`"
      @click="$emit('closeResultados')"
    >
      <img
        class="p-img"
        :src="imagen"
        style="width: 51px; height: 51px; object-fit: cover;"
        alt="producto imagen"
      >
      <div class="p-name ps-4 pe-2" v-html="resaltarResultado(nombre)" />
      <div class="p-price" v-html="formatPriceSup(precio)" />
    </NuxtLink>

  </div>
</template>
<style scoped>
strong {
  font-weight: 800 !important;
}
li:hover {
  background-color: #D9D9D9;
	cursor: pointer;
}
.hover-result {
		background-color: #1e1f2249;
		cursor: pointer;
		border:1px solid red;
	}
.p-container {
  display: grid;
  grid-template-columns: 52px 1fr 112px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 5px 0 5px 0;
}

.p-img { grid-area: 1/1/2/2; }
.p-name { 
  grid-area: 1/2/2/3; 
  color: #565656;
  width: 100%;
  height: 40px;
  font-size: 13px;
}
.p-price { 
  grid-area: 1/3/2/4; 
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #000;
  font-weight: 500;
}
.px-2 {
    padding-left: 0.5rem !important/* 8px */;
    padding-right: 0.5rem !important/* 8px */;
}
</style>
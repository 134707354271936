<template>
	<div ref="buttonContainer" class="relative z-30 select-none">
		<button
			id="btn-carrito"
			class="hidden lg:flex lg:items-center gap-2"
			:class="{ 'text-[#b1b1b1]': routes_cart.includes(route.path) }"
			@click="toggleCart"
		>
			<div class="relative pt-1 pe-3">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="39"
					height="35"
					viewBox="0 0 43 39"
					fill="none"
				>
					<path
						d="M39.4256 22.952L42.9547 7.10828C43.2095 5.96433 42.3573 4.875 41.2076 4.875H11.8853L11.2011 1.46174C11.0305 0.610975 10.2969 0 9.44574 0H1.79167C0.802144 0 0 0.818467 0 1.82812V3.04688C0 4.05653 0.802144 4.875 1.79167 4.875H7.00863L12.2528 31.0351C10.9982 31.7713 10.1528 33.1517 10.1528 34.7344C10.1528 37.0902 12.0245 39 14.3333 39C16.6422 39 18.5139 37.0902 18.5139 34.7344C18.5139 33.5405 18.0326 32.4618 17.2579 31.6875H32.9087C32.1341 32.4618 31.6528 33.5405 31.6528 34.7344C31.6528 37.0902 33.5245 39 35.8333 39C38.1422 39 40.0139 37.0902 40.0139 34.7344C40.0139 33.0455 39.0518 31.586 37.6564 30.8949L38.0683 29.0458C38.3231 27.9018 37.4709 26.8125 36.3212 26.8125H16.283L15.7944 24.375H37.6785C38.5151 24.375 39.2403 23.7844 39.4256 22.952Z"
						:fill="!routes_cart.includes(route.path) ? '#094F81' : '#b1b1b1'"
					/>
				</svg>
                <ClientOnly>
                    <transition name="bounce">
                        <div
                            v-show="storeCarro.carrito.length > 0"
                            class="absolute top-0 right-0 flex items-center justify-center bg-[#A23] text-[#FFF] rounded-full w-5 h-5"
                        >
                            {{ storeCarro.carrito.filter(e=>!e?.sugerencia).length }}
                        </div>
                    </transition>
                </ClientOnly>
			</div>
			Carrito
		</button>

		<transition
			name="slide-fade"
			@enter="onEnter"
			@after-enter="onAfterEnter"
			@leave="onLeave"
		>
			<div
				v-if="offCarrito.show"
				id="carrito-container"
				class="fixed right-0 top-0 z-40 h-screen w-80 overflow-y-auto bg-light-backgroundPage w-min-[300px] lg:w-[659px] py-4 pb-11"
				:style="{ top: `60px` }"
				aria-labelledby="modal-carrito"
				aria-modal="true"
				role="dialog"
			>
				<div
					v-if="!offCarrito.addedNew"
					class="flex justify-between items-center mb-5 px-5 py-2"
				>
					<div class="lg:text-[20px] font-semibold leading-6 text-light-primary flex items-center gap-2">
						<MdiIcon class="text-light-primary" icon="mdiCart" style="width: 23px;height: 23px;" />

						Mi Carrito
					</div>
					<button
						id="carrito-close"
						class="w-[33px] h-[33px]"
						@click="closeCart"
					>
                         <div class="close-icon before:bg-light-primary after:bg-light-primary" />
					</button>
				</div>

				<suspense>
					<template #default>
						<cart-content
							:loading="loadingPrecios"
							:cart-items="storeCarro.carrito"
							:added-new="offCarrito.addedNew"
							@close="closeCart"
                            @eliminarProducto="producto=>storeCarro.removeFromCarro(producto, true)"
						/>

					</template>
					<template #fallback>
						<div class="flex justify-center py-5">
							<the-loading size="45px" color="#0066AD" />
						</div>
					</template>
				</suspense>

				<div
					v-if="storeCarro.carrito.length > 0"
					class="flex flex-col w-full mt-3 bottom-0 right-0 bg-light-backgroundPage pb-5 sticky"
					style="box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25)"
				>
					<div
						class="flex justify-between mt-3 mb-3 px-3"
						style="font-size: 14px"
					>
						<span>
							<strong>{{ totalItems }}</strong>
							producto{{ totalItems > 1 ? "s" : "" }}
						</span>
						<div>
							Total:
							<strong v-html="formatPriceSup(total)" />
						</div>
					</div>
					<div class="flex md:pb-6">
						<button class="btn btn-add" @click="closeCart">
							Agregar productos
						</button>
						<button class="btn btn-finish" @click="finishPurchase">
							Finalizar compra
						</button>
					</div>
				</div>
			</div>
		</transition>
		<!-- Nuevo componente de overlay -->
        <TheOverlay v-model.showOver="offCarrito.show" />

	</div>
</template>

<script lang="ts" setup>
import { useCarroCompras, useShowOffCarro } from "../../stores/carrocompras";
import { ref, computed, watch, onMounted } from "vue";
import type { ResponseCalculoPrecios } from "../../types/responses";


//   const CartContent = defineAsyncComponent(() => import('./CartContent.vue'));
const CartContent = defineAsyncComponent(() => import("./CartContent.vue"));

const config = useRuntimeConfig();
const storeCarro = useCarroCompras();
const uselogin = useLogin();
const offCarrito = useShowOffCarro();
const router = useRouter();
const route = useRoute();

const buttonContainerHeight = ref(0);
const buttonContainer = ref(null);

const errorPreciosProductos = ref(null);
const loadingPrecios = ref(false);

const routes_cart = [
	"/carro-compras",
	"/formas-pago",
	"/formas-envio",
	"/datos-cliente",
	"/resumen-compra",
	"/detalle-compra",
	"/orden-compra",
	"/seguimiento-compra",
];

const toggleCart = () => {
	if (!routes_cart.includes(route.path)) {
		offCarrito.show = !offCarrito.show;
	}
};

const closeCart = () => {
	offCarrito.show = false;
	offCarrito.addedNew = false;
};

const finishPurchase = () => {
	router.push("/carro-compras");
	closeCart();
};

const fetchCartData = async () => {
	if (storeCarro.carrito.length === 0) {
		loadingPrecios.value = false;
		return;
	}

	const productos = storeCarro.carrito.map((e) => ({
		cantidad: e.cantidad,
		codigo: e.codigo,
		sugerencia: e?.sugerencia,
		sugerenciaProducto: e?.productoSugerencia,
		sugerenciaSubcategoria: e.sugerenciaSubcategoria,
	}));

	try {
		const response = await $fetch<ResponseCalculoPrecios>("/carro_compras", {
			baseURL: config.public["apibase"],
			method: "POST",
			headers: {
				Authorization: `Bearer ${uselogin.token}`,
			},
			body: {
				productos,
				forma_pago: {
					codigo: 0,
					codigo_pago: 0,
				},
			},
            onResponse({response}) {
                console.log(response);
            },
		});

		if (response.data?.productos) {
            for (let i = 0; i < storeCarro.carrito.length; i++) {

                const oldProduct = storeCarro.carrito[i];
                const updatedProduct = response.data.productos.find(
                    (p) => p.codigo === oldProduct.codigo,
                );
                if(updatedProduct) {
                    storeCarro.carrito[i].codigo = updatedProduct.codigo;
                    storeCarro.carrito[i].nombre = updatedProduct.nombre;
                    storeCarro.carrito[i].cantidad = updatedProduct.cantidad;
                    storeCarro.carrito[i].unitario = updatedProduct.unitario;
                    storeCarro.carrito[i].imagen = updatedProduct.imagen;
                    storeCarro.carrito[i].stock = updatedProduct.stock;
                }else{
                    storeCarro.carrito.splice(i, 1);
                }
                
            }
		}
	} catch (error) {
		errorPreciosProductos.value = error;
	} finally {
		loadingPrecios.value = false;
	}
};

watch(
	() => [offCarrito.show, offCarrito.addedNew],
	(offcarro) => {
		if(offcarro[0]) {
            loadingPrecios.value = true;
            fetchCartData();
        }else{
            offCarrito.addedNew = false;
        }
	},
);

const total = computed(() => {
	return storeCarro.carrito.reduce((acc, item) => {
		if (
			(item?.stock?.stock >= 0 && item?.stock?.ingreso_fecha !== "") ||
			item?.stock?.stock > 0
		) {

			return acc + item.cantidad * Number(item.unitario);
		}
		return acc;
	}, 0);
});

const totalItems = computed(() => {
    return storeCarro.carrito.reduce((acc, item) => {
      if (!item?.sugerencia) {
        return acc + item.cantidad;
      }
      return acc;
    }, 0);
});

onMounted(() => {
	buttonContainerHeight.value = buttonContainer.value?.offsetHeight || 0;
});

// Animation hooks
const onEnter = (el) => {
	el.style.transform = "translateX(100%)";
	el.style.opacity = "0";
};

const onAfterEnter = (el) => {
	el.style.transform = "translateX(0)";
	el.style.opacity = "1";
};

const onLeave = (el) => {
	el.style.transform = "translateX(100%)";
	el.style.opacity = "0";
};
</script>

<style scoped>
.close-icon {
	position: relative;
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.close-icon::before,
.close-icon::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 24px;
	height: 2px;
	/* background-color: black; */
	transition: transform 0.3s ease;
}

.close-icon::before {
	transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-icon:hover::before,
.close-icon:hover::after {
	background-color: #31a9fd;
}

.btn {
	width: 8rem;
	height: 50px;
	transition: all 0.3s ease;
}

.btn-add {
	color: #31a9fd;
	font-size: 14px;
	font-weight: 500;
	border-radius: 5px;
	border: 1px solid #31a9fd;
	margin: 0 auto;
}

.btn-finish {
	color: #fff;
	margin: 0 auto;
	font-size: 14px;
	font-weight: 500;
	border-radius: 5px;
	background: #31a9fd;
}

.btn:hover {
	transform: translateY(-2px);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 396px) {
	.btn {
		width: 160px;
		height: 50px;
	}
}

.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}
.bounce-leave-active {
	animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
}

/**Animacion y efectos overlay */
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>

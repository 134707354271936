<template>
  <Teleport
    v-if="showOver"
    to="main"
   >

  <div    
    class="fixed inset-0 bg-[black] bg-opacity-25 z-20 h-dvh"
    @click.stop="showOver = false"
    />
  </Teleport>
</template>

<script lang="ts" setup>
const showOver = defineModel<boolean>()
</script>
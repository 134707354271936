<script lang="ts" setup>
	import type { DataEmpresa } from '../../types/stores.d.ts';

	defineProps<{
		dataEmpresa: DataEmpresa
	}>();
	
	const route = useRoute();
	const config = useRuntimeConfig();
	const uselogin = useLogin();

	const email = ref()
	const loadingNewsletter= ref(false)
    
    const rutasExcluidas = ['carro-compras', 'formas-pago', 'formas-envio', 'datos-cliente', 'catalogo-caracteristicas-id-productoNombre', 'catalogo-opiniones-id-productoNombre']
	
    const mostrar = ()=>{
        if(rutasExcluidas.includes(route.name)) {
            return false
        }
		return true
	}
    
	const suscripto = ref(false)
	
	const onsubmitNewsletter = ()=>{
		if(!email.value && !loadingNewsletter.value) return
		loadingNewsletter.value = true
		try {
			$fetch("/newsletter", {
				baseURL: config.public["apibase"],
				method: "POST",
				headers: {
					Authorization: `Bearer ${uselogin.token}`,
				},
				body: {email: email.value},
				onResponse: ({ response }) => {

					if( response.status == 200 ){
						suscripto.value = true
					}
					loadingNewsletter.value = false
				},
			})
		} catch (error) {
			loadingNewsletter.value = false
			suscripto.value = true
		}
	}
	
</script>

<template>
	<footer class="z-50 bg-light-backgroundPage border-t-2 border-light-muted overflow-hidden">
		
		<ComunesFooterDatosEmpresa v-if="mostrar()" :data-empresa="dataEmpresa"/>
        <ComunesFooterNewsletter v-if="mostrar() && !suscripto" :loadingNewsletter="loadingNewsletter" :suscripto="suscripto" @onsubmitNewsletter="onsubmitNewsletter"/>
        <ComunesFooterNewletterOk v-else-if="mostrar()"/>
        <ComunesFooterLinks />
        <ComunesFooterBottom :data-empresa="dataEmpresa" />
	
	</footer>
</template>
<style scoped>
* {
	user-select: none;
}
</style>

import { ref, onMounted, onUnmounted } from 'vue'

export function useScrollPosition(threshold = 200) {
  const scrolled = ref(false)
  let lastScrollPosition = 0

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY
    scrolled.value = currentScrollPosition > threshold && currentScrollPosition > lastScrollPosition
    lastScrollPosition = currentScrollPosition
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  return { scrolled }
}
<template>
  <form class="bg-[#6B6B6B] text-[#fff] h-[113px] flex flex-col justify-center lg:flex-row items-center gap-4" @submit.prevent="onsubmitNewsletter">
        <label class="text-[20px] text-[#fff] tracking-[1%]">
            ¡Recibí las mejores ofertas!
        </label>
        <div class="flex gap-3 justify-center">

            <input
            v-model="email"
            class="max-w-[533px] lg:w-[500px] border border-[#fff] rounded-md bg-light-backgroundPage p-1 px-3"
            style="color: black;"
            placeholder="Ingresá tu email"
            type="email"
            required
            onkeypress="return !((event.charCode >= 0 && event.charCode <= 31) || event.charCode == 32)"
            @keyup.enter="onsubmitNewsletter"
            >
            <button type="submit" class="text-[#fff] flex justify-center items-center rounded-lg bg-light-primaryDark text-center w-[132px] h-[38px] px-5 py-1">
                <TheLoading v-if="loadingNewsletter" width="35px" height="35px" />
                <template v-else>
                    Suscribirme
                </template>
            </button>
        </div>
    </form>
</template>

<script lang="ts" setup>
defineProps<{suscripto:boolean, loadingNewsletter:boolean}>()

defineEmits(['onsubmitNewsletter'])
const loadingNewsletter = ref(false)
const email = ref("")
    const onsubmitNewsletter = ()=>{
		if(!email.value && !loadingNewsletter.value) return
        $emit('onsubmitNewsletter', email.value)
	}
</script>

<style scoped>

</style>
<template>
    <!-- hide-scroll overflow-y-auto overscroll-contain  -->
  <div
		class="fixed left-0 top-14 z-30 pt-4 px-2 pb-24 h-dvh w-80 bg-light-backgroundPage transition-transform duration-300 ease-in-out"
		tabindex="-1"
        :class="[
            showMenu ? 'translate-x-0' : '-translate-x-full',
            'overflow-y-auto overscroll-contain'
        ]"
	>

		<div v-if="!selectedCategory" class="mt-0 flex flex-col font-medium lg:p-0">
            <!-- Login/Register Section -->
            <div v-if="!uselogin.token" class="flex items-center py-3">
                <MdiIcon class="mx-4" icon="mdiAccountCircle" style="width: 23px;height: 23px;font-size: 23px;" />
				<NuxtLink class="cursor-pointer whitespace-nowrap text-[14px] font-semibold text-light-primaryDarken" to="/login" @click="showMenu = false">
					Ingresar a mi cuenta
				</NuxtLink>
				<div class="mx-1 text-[14px] font-semibold">|</div>
				<NuxtLink
					class="text-blue-700 text-[14px] font-semibold text-light-error"
					to="/registro"
					@click="showMenu = false"
				>
					Registrarme
				</NuxtLink>
			</div>
            <div class="ps-4 pe-3" v-else>
                <!-- User Info Section -->
                <TheLoading v-if="loadingLogout" class="mx-auto" size="25px"/>
                <template v-else>
                    <NuxtLink @click="showMenu=false" to="/mi-cuenta">
                        Hola,
                        <span class="text-light-muted capitalize">
                            {{ `${uselogin.nombre} ${uselogin.apellido}` }}
                        </span>
                    </NuxtLink>
                    <NuxtLink class="block mt-2 text-center text-light-primaryDark" @click="logout">Cerrar sesion</NuxtLink>
                </template>
            </div>
            
            <!-- Navigation Links -->
            <nav-link to="/carro-compras" icon="mdiCart" iconColor="" @click="showMenu = false">Carro de compras</nav-link>
            <nav-link to="/seguimiento-compra" icon="mdiMapMarkerRadiusOutline" iconColor="" @click="showMenu = false">Seguimiento de compra</nav-link>
            <nav-link to="/mi-cuenta" icon="mdiAccountCircle" iconColor="" @click="showMenu = false">Mi cuenta</nav-link>
            <nav-link class="flex items-center py-3" icon="mdiInformationOutline" iconColor="" to="/centro-ayuda" @click="showMenu = false">
                Centro de ayuda
            </nav-link>
			
			<div class="h-[46px] py-3 font-semibold text-[14px] text-[#fff] bg-[#31AAFF] flex items-center cursor-pointer" @click="()=>{showCats = !showCats;showAllCats = false}">
				<i class="w-5 h-5 ms-5 me-4">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M17 9C17 9.88366 16.2837 10.6 15.4 10.6C14.5164 10.6 13.8001 9.88366 13.8001 9C13.8001 8.11635 14.5164 7.4 15.4 7.4C16.2837 7.4 17 8.11635 17 9Z"
							fill="white"
						/>
						<path
							d="M17 2.6C17 3.48366 16.2837 4.2 15.4 4.2C14.5164 4.2 13.8001 3.48366 13.8001 2.6C13.8001 1.71635 14.5164 1 15.4 1C16.2837 1 17 1.71635 17 2.6Z"
							fill="white"
						/>
						<path
							d="M17 15.4C17 16.2837 16.2837 17 15.4 17C14.5164 17 13.8001 16.2837 13.8001 15.4C13.8001 14.5163 14.5164 13.8 15.4 13.8C16.2837 13.8 17 14.5163 17 15.4Z"
							fill="white"
						/>
						<path
							d="M4.19992 2.6C4.19992 3.48366 3.48359 4.2 2.59996 4.2C1.71633 4.2 1 3.48366 1 2.6C1 1.71634 1.71633 1 2.59996 1C3.48359 1 4.19992 1.71634 4.19992 2.6Z"
							fill="white"
						/>
						<path
							d="M13.8001 9C13.8001 9.88366 14.5164 10.6 15.4 10.6C16.2837 10.6 17 9.88366 17 9C17 8.11635 16.2837 7.4 15.4 7.4C14.5164 7.4 13.8001 8.11635 13.8001 9ZM13.8001 9H9.50018M13.8001 2.6C13.8001 3.48366 14.5164 4.2 15.4 4.2C16.2837 4.2 17 3.48366 17 2.6C17 1.71635 16.2837 1 15.4 1C14.5164 1 13.8001 1.71635 13.8001 2.6ZM13.8001 2.6H4.49991M13.8001 15.4C13.8001 16.2837 14.5164 17 15.4 17C16.2837 17 17 16.2837 17 15.4C17 14.5163 16.2837 13.8 15.4 13.8C14.5164 13.8 13.8001 14.5163 13.8001 15.4ZM13.8001 15.4H9.20019C8.75837 15.4 8.40021 15.0418 8.40021 14.6V3.4M4.19992 2.6C4.19992 3.48366 3.48359 4.2 2.59996 4.2C1.71633 4.2 1 3.48366 1 2.6C1 1.71634 1.71633 1 2.59996 1C3.48359 1 4.19992 1.71634 4.19992 2.6Z"
							stroke="white"
							stroke-width="2"
							stroke-linecap="round"
						/>
					</svg>
				</i>
				<div class="inline-block">Categorías</div>
			</div>
			<Transition>
				<div 
					v-if="menu"
					v-show="showCats" 
					class="overflow-y-auto"
				>
					<template
						v-for="(item) in menu"
						:key="item"
					>
						<template v-if="item.tipo==1">
							<template v-for="categoria, indexCat in item.items" :key="indexCat">

								<template v-if="indexCat <= 6">
									<div
										class="flex justify-between items-center h-14 py-3 ps-3 pe-6 text-[#474747] text-[14px] border-b border-[#717171] cursor-pointer"
										@click="setSubCategories(categoria)"
									>
										<div class="inline-block text-light-secondaryDark font-medium">{{ categoria.nombre }}</div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="9"
											height="13"
											viewBox="0 0 9 13"
											fill="none"
										>
											<path
												d="M2.33134 0L0 2.32832L3.88557 6.20885L0 10.0894L2.33134 12.4177L8.54826 6.20885L2.33134 0Z"
												fill="#5B5B5B"
											/>
										</svg>
									</div>
								</template>
								<template v-if="indexCat == 6">
									<Transition>
										<div v-show="showAllCats">
											<template
												v-for="(categoria2, indexCat2) in menu.filter(e=>e.tipo==1)[0].items"
												:key="indexCat2"
											>
												<div
													v-if="indexCat2 > 6"
													class="flex justify-between items-center h-14 py-3 ps-3 pe-6 text-[#474747] text-[14px] border-b border-[#717171] cursor-pointer"
													@click="setSubCategories(categoria2)"
												>
													<div class="inline-block">{{ categoria2.nombre }}</div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="9"
														height="13"
														viewBox="0 0 9 13"
														fill="none"
													>
														<path
															d="M2.33134 0L0 2.32832L3.88557 6.20885L0 10.0894L2.33134 12.4177L8.54826 6.20885L2.33134 0Z"
															fill="#5B5B5B"
														/>
													</svg>
												</div>
											</template>
										</div>
									</Transition>
								</template>
							</template>

						</template>

					</template>

					<button v-show="!showAllCats" id="more-cats" class="w-full h-9 py-3 text-[#303030] font-semibold text-[14px] border-b-[0.7px] border-[#474747]" @click="showAllCats = !showAllCats">
						Ver todo
					</button>
				</div>
			</Transition>
		</div>

		<div v-else class="flex flex-col font-medium -mt-4 -mx-2 pb-7">
			<div
				class="h-[46px] py-5 font-semibold text-[14px] text-[#fff] bg-[#31AAFF] flex items-center justify-between cursor-pointer ps-5"
				@click="setSubCategories(null)"
			>
				{{ selectedCategory.nombre }}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="45"
					height="34"
					viewBox="0 0 45 34"
					fill="none"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M22.7071 10.2929C23.0976 10.6834 23.0976 11.3166 22.7071 11.7071L18.4142 16H34C34.5523 16 35 16.4477 35 17C35 17.5523 34.5523 18 34 18H18.4142L22.7071 22.2929C23.0976 22.6834 23.0976 23.3166 22.7071 23.7071C22.3166 24.0976 21.6834 24.0976 21.2929 23.7071L15.2929 17.7071C14.9024 17.3166 14.9024 16.6834 15.2929 16.2929L21.2929 10.2929C21.6834 9.90237 22.3166 9.90237 22.7071 10.2929Z"
						fill="white"
					/>
					<rect width="45" height="34" fill="#D9D9D9" fill-opacity="0.01" />
				</svg>
			</div>
				<template
				v-for="sub in selectedCategory.items"
				:key="sub"
				>
					<NuxtLink
						class="flex justify-between items-center h-14 py-3 ps-3 pe-6 text-[#474747] text-[14px] border-b border-[#717171] cursor-pointer font-medium"
						:to="sub.link"
						@click="showMenu = false"
					>
						{{ sub.nombre }}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="9"
							height="13"
							viewBox="0 0 9 13"
							fill="none"
						>
							<path
								d="M2.33134 0L0 2.32832L3.88557 6.20885L0 10.0894L2.33134 12.4177L8.54826 6.20885L2.33134 0Z"
								fill="#5B5B5B"
							/>
						</svg>
					</NuxtLink>
			</template>
		</div>
        <nav-link to="/catalogo/ultimos-ingresos" icon="mdiFire" iconColor="" @click="showMenu = false">Ultimos ingresos</nav-link>
        
        <nav-link to="/catalogo/ofertas" icon="mdiLabelPercentOutline" iconColor="blue" @click="showMenu = false">
				Productos en oferta
        </nav-link>

        <nav-link to="/catalogo/liquidacion" icon="mdiTag" iconColor="#ffcd00" @click="showMenu = false">
            Productos en liquidación
        </nav-link>
        <nav-link to="/catalogo/ofertas" icon="mdiInvoiceArrowLeft" iconColor="blue" @click="showMenu = false">         
            Productos en preventa
        </nav-link>
	</div>
</template>

<script lang="ts" setup>
import type { MenuItemCat, ResponseDatosNavbar } from '../../types/responses';
import { useLogin } from '../../stores/states';
import { reloadNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { ref } from 'vue';
const config = useRuntimeConfig()
const uselogin = useLogin()

defineProps<{
  menu: ResponseDatosNavbar
}>()

const showMenu = defineModel<boolean>()

const showAllCats = ref(false)
const showCats = ref(true)


const selectedCategory = ref<MenuItemCat>();
const setSubCategories = (category: MenuItemCat) => {
	selectedCategory.value = category;
};

const loadingLogout = ref(false);

const logout = async() => {
	loadingLogout.value = true;
	try{
        const token = uselogin.token; 
        uselogin.token = null;
        uselogin.nombre = null;
        uselogin.apellido = null;
        uselogin.user_img = null;
        
        $fetch(`/logout`, {
            baseURL: config.public["apibase"] as string,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` }
        })
    } catch(e){
        // #
    } finally {
        await navigateTo('/', { replace: true })
        loadingLogout.value = false;
    }
};

</script>

<style scoped>

#menu {
	cursor: pointer;
}
input#menu {
	display: none;
}




</style>
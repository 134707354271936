<template>
  <ClientOnly>
    <transition-group
      name="list"
      tag="div"
      class="flex flex-col"
    >
      <template v-if="shouldShow">
        <NuxtLink
          v-for="subcat in items"
          :key="subcat.nombre"
          :to="subcat.link"
          class="subcat-link"
          @click="$emit('closeMenu')"
        >
          <span class="subcat">{{ subcat.nombre }}</span>
        </NuxtLink>
      </template>
    </transition-group>
  </ClientOnly>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

interface Subcategory {
  nombre: string;
  link: string;
}

const props = defineProps<{
  items: Subcategory[];
  show: boolean;
}>();

defineEmits(['closeMenu'])

const shouldShow = computed(() => {
  return props.show && props.items.length > 0;
});
</script>
<style scoped>
.subcat-link {
  margin-bottom: 5px;
}

.subcat {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.675px;
  position: relative;
  transition: color 0.2s ease;
}

.subcat::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s ease;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-leave-active {
  position: absolute;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
<script lang="ts" setup>
const props = defineProps({
  codigo: { type: Number, required: true },
  nombre: { type: String, required: true },
  imagen: { type: String, required: false },
  precio: { required: false },
  resaltar: { type:Boolean, required:false },
  tipo: { type:String, required: true },
  link: { type:String, required: false },

})
const router = useRouter()

const navegar = () =>{
  switch (props.tipo) {
    case 'categoria':
      router.push(`/catalogo/categoria/${props.codigo}-${convertToUrlFriendly(props.nombre)}`)    
      break;
    case 'subcategoria':
      router.push(`/catalogo/subcategoria/${props.codigo}-${convertToUrlFriendly(props.nombre)}`)    
      break;
    case 'catalogo':
      router.push(`/catalogo/${props.codigo}-${convertToUrlFriendly(props.nombre)}`)    
      break;
    default:
      break;
  }
}
</script>
<template>

<NuxtLink class="card mb-3" :to="link">
  <div class="img">
      <img class="mx-auto" :src="imagen" width="51" height="51"/>
  </div>
  <div class="title line-clamp-2">{{ nombre }}</div>
  <div class="price text-end pe-3" v-html="formatPriceSup(precio)"></div>
</NuxtLink>
</template>
<style scoped>
.texto{
  font-weight: 500;
  font-size: 13px;
  text-overflow: ellipsis;
}
.hover-key{ 
  background-color: #D9D9D9 !important;
}
.card {
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.6fr;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}

.img { grid-area: 1 / 1 / 2 / 2; }
.title { 
  grid-area: 1 / 2 / 2 / 3; 
  font-size: 13px;
  height: 40px;  
  text-overflow: ellipsis;
}
.price { 
  grid-area: 1 / 3 / 2 / 4; 
  font-size: 13px;
}
</style>
<template>
	<div
		class="flex text-white bg-light-primaryDark text-[#fff]"
		style="height: 35px"
	>
		<div
			class="flex items-center justify-center container-menu"
			style="margin: 0 auto; max-width: 1440px; height: 35px"
		>
			<template v-for="(itemMenu, index) in menu" :key="index">
				<div
					v-if="itemMenu.tipo == 1"
					id="btn-categorias"
					class="relative flex items-center justify-center pointer gap-2"
				>
					<button
						class="flex items-center gap-1"
						@click="togglePopover"
						@mouseover="showPopover = true"
					>
						Categorías
						<svg
							:class="{ 'rotate-180': showPopover }"
							class="ms-1 transition-transform duration-200"
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7ZM0 8.5C2.02656e-07 3.80558 3.80558 -2.02656e-07 8.5 0C13.1944 2.02656e-07 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 -2.02656e-07 13.1944 0 8.5ZM5.34896 7.04896C5.68091 6.71701 6.2191 6.71701 6.55104 7.04896L8.5 8.99792L10.449 7.04896C10.7809 6.71701 11.3191 6.71701 11.651 7.04896C11.983 7.38091 11.983 7.9191 11.651 8.25104L9.10104 10.801C8.94164 10.9604 8.72543 11.05 8.5 11.05C8.27457 11.05 8.05837 10.9604 7.89896 10.801L5.34896 8.25104C5.01701 7.9191 5.01701 7.38091 5.34896 7.04896Z"
								fill="white"
							/>
						</svg>
					</button>

					<transition
						enter-active-class="transition ease-out duration-200"
						enter-from-class="opacity-0 transform scale-95"
						enter-to-class="opacity-100 transform scale-100"
						leave-active-class="transition ease-in duration-150"
						leave-from-class="opacity-100 transform scale-100"
						leave-to-class="opacity-0 transform scale-95"
					>
						<div
							v-show="showPopover"
							class="overflow-auto popover bg-light-primaryDark"
							@mouseleave="hidePopover"
						>
                            <div class="popover-content">

                            
                                <div class="grid grid-cols-4 gap-x-0 gap-y-10">
                                    <div class="flex justify-end col-span-4">
                                        <svg
                                            @click="hidePopover"
                                            viewBox="0 0 1024 1024"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20px"
                                            height="20px"
                                            fill="#f3f3f3"
                                            stroke="#f3f3f3"
                                        >
                                            <path
                                                fill="#f3f3f3"
                                                d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                                            />
                                        </svg>
                                    </div>
                                    <template
                                        v-for="(cat, indexCat) in itemMenu.items"
                                        :key="indexCat"
                                    >
                                        <div
                                            v-memo="[cat.nombre, showMore[cat.nombre]]"
                                            class="flex flex-col"
                                        >
                                            <NuxtLink class="mb-2 capitalize cat" :to="cat.link">
                                                {{ cat.nombre }}
                                                <hr class="w-[45%]" />
                                            </NuxtLink>

                                            <template
                                                v-for="(sub, indexsub) in cat.items.slice(0, 5)"
                                                :key="sub.nombre"
                                            >
                                                <NuxtLink :to="sub.link" @click="hidePopover">
                                                    <span class="subcat">{{ sub.nombre }}</span>
                                                </NuxtLink>
                                            </template>
                                            <NavSubcategoriaList
                                                :items="cat.items.slice(5)"
                                                :show="showMore[cat.nombre] ?? false"
                                                @closeMenu="hidePopover"
                                            />

                                            <div
                                                v-if="cat.items.length > 5"
                                                class="mt-1 vermas cursor-pointer"
                                                @click="toggleShowMore(cat.nombre)"
                                            >
                                                {{ showMore[cat.nombre] ? "Ver menos <<" : "Ver más >>" }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>                     
						</div>
					</transition>
				</div>
				<template v-else-if="itemMenu.tipo == 2">
					<NuxtLink
						class="flex items-center justify-center cursor-pointer select-none"
						:to="itemMenu.link"
						style="margin: 0 0 0 30px"
					>
						<MdiIcon
							v-if="itemMenu.icono"
							class="me-2"
							style="width: 24px; height: 24px"
							:icon="itemMenu.icono"
						/>
						{{ itemMenu.nombre }}
					</NuxtLink>
				</template>
			</template>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import type { ResponseDatosNavbar } from "../../types/responses";

const props = defineProps<{ menu: ResponseDatosNavbar }>();
const showPopover = ref(false);
const showMore = ref<{ [key: string]: boolean }>({});

const menuItems = computed(() => props.menu.filter((item) => item.tipo === 1));

onMounted(() => {
	menuItems.value.forEach((item) => {
		item.items.forEach((cat) => {
			showMore.value[cat.nombre] = false;
		});
	});
	document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
	document.removeEventListener("click", handleClickOutside);
});

const togglePopover = () => {
	showPopover.value = !showPopover.value;
};

const hidePopover = () => {
	showPopover.value = false;
};

const toggleShowMore = (catName: string) => {
	showMore.value[catName] = !showMore.value[catName];
};

const handleClickOutside = (event: MouseEvent) => {
	const popover = document.querySelector(".popover");
	const button = document.querySelector("#btn-categorias");
	if (
		popover &&
		!popover.contains(event.target as Node) &&
		!button?.contains(event.target as Node)
	) {
		hidePopover();
	}
};
</script>

<style scoped>
.container-menu {
	margin: 0 auto;
	max-width: 1440px;
	height: 35px;
	position: relative;
}

.popover {
	position: fixed;
	top: 190px; /* Adjust this value to match the height of your navbar */
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1324px;
	max-height: calc(100vh - 35px);
	overflow-y: auto;
	border: 1px solid #ccc;
	border-radius: 25px;
	z-index: 1000;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: all 0.2s ease-in-out;
}

.popover-content {
	padding: 30px;
	max-width: 1324px;
	margin: 0 auto;
}

@media (max-width: 1324px) {
	.popover {
		width: 95%;
	}
}
.cat,
.subcat,
.vermas {
	transition: color 0.2s ease;
}

.cat:hover,
.subcat:hover,
.vermas:hover {
	color: #e0e0e0;
}

.subcat::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	transform: scaleX(0);
	transform-origin: left;
	transition: transform 0.2s ease;
}

/* .subcat:hover::after {
	transform: scaleX(1);
} */

/* .popover {
    position: absolute;
    padding: 30px !important;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    width: 1324px;
    max-height: 70vh;
    border: 1px solid #ccc;
    border-radius: 25px;
    z-index: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
  }
  
  .cat, .subcat, .vermas {
    transition: color 0.2s ease;
  }
  
  .cat:hover, .subcat:hover, .vermas:hover {
    color: #e0e0e0;
  }
  
  .subcat::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease;
  }
  
  .subcat:hover::after {
    transform: scaleX(1);
  } */
</style>

<script lang="ts" setup>
	import { useCarroCompras } from '../stores/carrocompras';
	import { useLogin } from '../stores/states';
	
    const showBuscador = defineModel<boolean>()
	
	const config = useRuntimeConfig();
	const useCarro = useCarroCompras();
	const router = useRouter()
	const uselogin = useLogin();
	const buscador = ref({
		listado: {
			productos: [],
			categorias: [],
			subcategorias: [],
			pagina: 1,
		},
		loading: false,
	});
	const items = ref([]);
	const inputSearch = ref();
	const buscadorProductos = inputBuscador => {
		if (!inputBuscador) {
			buscador.value = {
				listado: {
					productos: [],
					categorias: [],
					subcategorias: [],
					pagina: 1,
				},
				loading: false,
			};
			return;
		}

		buscador.value.loading = true;
		const productos_carro = useCarro.carrito.map(e => ({
			codigo: e.codigo,
			cantidad: e.cantidad,
		}));

        $fetch(`/buscador-simple`, {
            key: 'productos-por-subcat',
            baseURL: config.public['apibase'],
            method: 'POST',
            headers: {
                Authorization: `Bearer ${uselogin.token}`,
            },
            body: {
                query: inputBuscador.target.value,
                // productos: productos_carro,
                // pagina: 1,
            },
            onResponse: ({ response, options }) => {
                console.log(response);
                console.log(options.body);
                if (response.status == 200) {
                    items.value = [];

                    // if (
                    // 	response._data?.categorias?.length > 0 ||
                    // 	response._data?.subcategorias?.length > 0
                    // ) {
                    // 	items.value.push({
                    // 		codigo: 0,
                    // 		title: 'Categorias',
                    // 		imagen: null,
                    // 		envioGratis: null,
                    // 	});
                    // }
                    // items.value = [
                    // 	...items.value,
                    // 	...response._data?.categorias?.map(e => ({
                    // 		codigo: e.codigo,
                    // 		title: e.nombre,
                    // 		imagen: e?.imagen,
                    // 		envioGratis: false,
                    // 		tipo: 0,
                    // 	})),
                    // ];

                    // items.value = [
                    // 	...items.value,
                    // 	...response._data?.subcategorias?.map(e => ({
                    // 		codigo: e.codigo,
                    // 		title: e.nombre,
                    // 		imagen: e?.imagen,
                    // 		envioGratis: false,
                    // 		tipo: 1,
                    // 	})),
                    // ];

                    // if (response._data.productos.length > 0) {
                    //     items.value.push({
                    //         codigo: 0,
                    //         title: 'Productos',
                    //         imagen: null,
                    //         envioGratis: null,
                    //         tipo:0,
                    //     });
                    // }

                    items.value = [
                        ...items.value,
                        ...response._data.productos.map(e => ({
                            ...e,
                            tipo: 2,
                        })),
                    ];
                } else {
                    items.value = [];
                }

                buscador.value.loading = false;
            },
        })
	};
	// const ruta = (tipoItem, codigo, nombre) => {
	// 	switch (tipoItem) {
	// 		case 0:
	// 			return `/catalogo/categoria/${codigo}-${convertToUrlFriendly(nombre)}`;
	// 		case 1:
	// 			return `/catalogo/subcategoria/${codigo}-${convertToUrlFriendly(nombre)}`;
	// 		case 2:
	// 			return `/catalogo/${codigo}-${convertToUrlFriendly(nombre)}`;
	// 		case 3:
	// 			return `/catalogo/buscador/${inputSearch.value}`;
	// 		case 'Categorias':
	// 			return `/catalogo/categorias`;
	// 		case 'Productos':
	// 			return `/catalogo`;
	// 		default:
	// 			return '/';
	// 	}
	// };
	// const resaltarResultado = n => {
	// 	if (!inputSearch.value && n) {
	// 		return n;
	// 	}
	// 	if(!n){
	// 		return
	// 	}
	// 	const retorno = n.replace(
	// 		inputSearch.value.toLowerCase(),
	// 		` <strong>${inputSearch.value}</strong>`
	// 	);
	// 	return retorno;
	// };
	const mostrarResultados = input => {
		if(input == ''){
			return
		}
		router.push(`/catalogo/buscador/${input}`)
		showBuscador.value = false
	};

</script>

<template>
	<div
        v-if="showBuscador"
		class="fixed inset-0 z-50 flex items-center justify-center pb-4 bg-[black] bg-opacity-50"
	>
        <div class="w-full h-full bg-light-backgroundPage overflow-auto transition-transform" :class="{'dialog-enter-active': !showBuscador, 'dialog-leave-active': showBuscador}">
            <div class="shadow-md">
                <div class="relative">
                    <input
                        id="buscadorMobile"
                        v-model="inputSearch"
                        class="h-14 border-none w-full pl-10 py-2 mb-2"
                        type="text"
                        placeholder="¿Qué estás buscando?"
                        :autofocus="showBuscador"
                        @input="buscadorProductos"
                        @keyup.enter="mostrarResultados($event.target.value)"
                    >
                    <div
                        class="absolute inset-y-0 left-0 flex items-center px-3"
                        @click="inputSearch = ''; items=[]; showBuscador = false"
                    >
                        <MdiIcon class="cursor-pointer" icon="mdiArrowLeft" style="width: 23px;height: 23px;font-size: 23px;"  />
                    </div>
                    <div class="absolute inset-y-0 right-0 flex items-center pe-3">
                        
                        <i v-if="buscador.loading" class="mdi mdi-loading mdi-spin"/>
                        <MdiIcon v-if="!buscador.loading && inputSearch" class="cursor-pointer" icon="mdiClose" style="width: 23px;height: 23px;font-size: 23px;"  />

                    </div>
                    <NavbarItemBuscadorMobile
                        v-for="item in items"
                       :key="item.codigo"
                        :codigo="item.codigo"
                        :nombre="item.nombre"
                        :imagen="item.imagen"
                        :precio="item.precio"
                        :resaltar="item.resaltar"
                        :tipo="Number(item.tipo)"
                        :link="item.url"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
.dialog-enter-active {
  transform: translateY(100%);
}
.dialog-leave-active {
  transform: translateY(0);
}
strong {
	 font-weight:700 !important;
}
.item-title {
	font-size: 14px;
	font-weight: 500;
}
.item-subitem {
	font-size: 14px;
	font-weight: 500;
}
.price {
	font-size: 14px;
	font-weight: 700;
}
.item-subitem::first-letter {
	text-transform: uppercase;
}
</style>

    <template>
        <header
            class="sticky z-30 lg:bg-light-backgroundPage top-0 transition-transform duration-300 ease-in-out"
            :class="{ 'lg:-translate-y-full ': scrolled, 'lg:translate-y-0 ': !scrolled }"
        >
            <NavInfoBar class="hidden lg:block" :data-empresa="dataEmpresa" />
            <nav
                class="navbar mx-auto flex items-center bg-light-primaryDark h-[61px] lg:h-[122px] lg:max-w-[1440px] lg:justify-around lg:bg-light-backgroundPage lg:px-0"
            >
                <div class="navbar-left lg:min-w-[111px]">
                    <NavLeftMobile class="lg:hidden" :show-menu="showMenu" @toggle="showMenu = !showMenu" />
                    
                        <NuxtLink class="hidden lg:block" to="/">
                            <img class="h-[95px] w-auto" src="/images/logopng.webp" alt="Logo">
                        </NuxtLink>
                    
                </div>
                <div
                    class="navbar-mid flex-grow flex lg:w-full lg:min-w-[441px] lg:max-w-[480px] lg:ps-5 xl:mx-auto xl:max-w-[636px] xl:ps-0"
                >
                <NuxtLink class="lg:hidden mx-auto" to="/">
                    <img src="/images/logo_inverso.svg" alt="Logo">
                </NuxtLink>
                    <NavbarBuscador />
                </div>

                <div
                    class="flex items-center justify-between gap-3 lg:flex lg:min-w-[430px] lg:justify-evenly xl:mx-auto xl:max-w-[530px] xl:justify-evenly xl:px-3 2xl:justify-between"
                >
                    <NavRightMobile class="lg:hidden" :cantProductos="useCarro?.carrito.length" @toggle-search="showBuscadorMobile = !showBuscadorMobile" @toggle-cart="toggleCarrito" />
                    <NavUserMenu class="hidden lg:block" />
                    <NavCarrito />
                </div>
            </nav>
            <NavCategorias
                class="hidden lg:block transition-transform duration-300 ease-in-out"
                :menu="menu"
            />
        </header>

        <TheOverlay v-model.showOver="showMenu" />

        <!-- Menu mobile -->
        <NavMobileMenu v-model.showMenu="showMenu" :menu="menu" />
        <!-- Buscador que funciona en mobile -->
        <BuscadorMobile v-model.showBuscador="showBuscadorMobile" />
    </template>
    <script lang="ts" setup>
    import { useCarroCompras, useShowOffCarro } from "../../stores/carrocompras.ts";
    import type { DataEmpresa } from '../../types/stores';
    
    import { useScrollPosition } from '../../composables/useScrollPosition'
    import { useMenuData } from '../../composables/useMenuData'

    defineProps<{
        dataEmpresa: DataEmpresa
    }>();

    const { scrolled } = useScrollPosition(200)
    const { menu } = useMenuData()

    const offCarrito = useShowOffCarro();
    const useCarro = useCarroCompras()

    const showMenu = ref(false);
    const showBuscadorMobile = ref(false);

    let lastScrollPosition = 0;

    if(import.meta.client) {
        lastScrollPosition = window.scrollY;
    }


    const handleScroll = () => {
        const currentScrollPosition = window.scrollY;
        
        scrolled.value = currentScrollPosition > 200 && currentScrollPosition > lastScrollPosition;
        lastScrollPosition = currentScrollPosition;
    };
    
    const toggleCarrito = ()=> offCarrito.show = !offCarrito.show

    onMounted(() => {
        // const btnCarritoMob = document.getElementById('btn-cart-mob');
        // const carritoContainer = document.getElementById('carrito-container');
        // const btnCarritoClose = document.getElementById('carrito-close')
        
        // btnCarritoMob?.addEventListener('click', () => {
        // 	carritoContainer?.classList.toggle('translate-x-full')
        // 	console.log('clock');
        // 	// offCarrito.show = !offCarrito.show
        // })
        // document.addEventListener('click', (e) => {
        // 	if(routes_cart.includes(route.path)) {
        // 		return
        // 	}

        // 	if(!carritoContainer.contains(e.target) && !btnCarritoMob.contains(e.target)) {
        // 		console.log('click afuer');
        // 		closeCarrito()
        // 		// carritoContainer?.classList.toggle('translate-x-full')
        // 	}else{
        // 		console.log('click adentro');
        // 	}
        // })
        window.addEventListener("scroll", handleScroll);
    });
    onBeforeUnmount(() => {
        window.removeEventListener("scroll", handleScroll);
    });

    </script>

    <style scoped>


    #menu {
        cursor: pointer;
    }

    li:deep() {
        font-size: 14px;
    }

    label {
        display: block;
        width: 39px;
        height: 28px;
        background: url(images/menu.png) no-repeat left center;
        cursor: pointer;
    }

    input#menu {
        display: none;
    }

    input:checked ∼ label {
        background: url(/images/menu-close.png) no-repeat left center;
    }

    .navbar {
        padding: 0 0 0 15px;
    }

    .navbar-left {
        display: flex;
        justify-content: space-between;
        /* grid-gap: 20px; */
        align-items: center;
    }
    .navbar-center img {
        max-width: 150px;
        /* Ajusta el tamaño del logo según tus necesidades */
    }

    .item {
        display: flex;
        align-items: center;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .item > i {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        margin-left: 19px;
    }

    .item-cat {
        display: flex;
        align-items: center;
        height: 46px;
        padding-top: 13px;
        padding-bottom: 13px;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: normal;

        background-color: #31aaff;
    }

    .item-cat > i {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        margin-left: 19px;
    }

    .subitem-cat {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        padding: 13px 12px 13px 23px;
        color: #474747;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        border-bottom: 1px solid #717171;
    }

    @media (min-width: 1024px) {
        input::placeholder {
            font-weight: 300;
            font-size: 15px;
            font-family: Roboto;
            font-style: italic;
            line-height: normal;
        }
        .navbar {
            padding: 0 15px 0 15px;
        }

        .navbar-left {
            display: flex;
            justify-content: center;
            width: 254px;
            height: 118px;
        }

        .navbar-mid {
            position: relative;
            display: flex;
            align-items: center;
        }

        .navbar-mid > input {
            width: 100%;
            max-width: 636px;
            height: 42px;
            border: 0.6px solid #7a7a7a;
            border-radius: 8px;
        }
    }
    </style>
